import React from 'react';

const styles = {
  color: '#fff',
  height: '176px'
};

const ModalCardBanner = ({title, imagePath}) => (
  <div className="mdl-card__title" style={{ ...styles, backgroundImage: `url(${imagePath})`}}>
    <h2 className="mdl-card__title-text">{title}</h2>
  </div>
);

export default ModalCardBanner;

import React, { Component } from 'react';
import HeaderBar from "./components/HeaderBar";
import IntroductionModal from "./components/IntroductionModal";
import AddFormModal from "./components/AddFormModal";
import ViewSpotModal from "./components/ViewSpotModal";
import {apiUrl} from "./Constants";
import {geolocated} from "react-geolocated";
import LeafletMap from "./components/LeafletMap";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spots: null,
      isLoading: false,
      showIntroductionModal: false,
      showAddFormModal: false,
      showViewSpotModal: false,
      selectedSpot: null,
    };

    this.toggleIntroductionModal = this.toggleIntroductionModal.bind(this);
    this.toggleAddFormModal = this.toggleAddFormModal.bind(this);
    this.toggleViewSpotModal = this.toggleViewSpotModal.bind(this);
    this.filterCategory = this.filterCategory.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    fetch(apiUrl + 'all')
      .then(response => response.json())
      .then(data => this.setState({ spots: data, isLoading: false }));
  }

  toggleIntroductionModal(){
    this.setState({ showIntroductionModal: this.state.showIntroductionModal !== true});
  }

  toggleAddFormModal(){
    this.setState({ showAddFormModal: this.state.showAddFormModal !== true});
  }

  toggleViewSpotModal(spot = null){
    this.setState({
      showViewSpotModal: this.state.showViewSpotModal !== true,
      selectedSpot: spot
    });
  }

  filterCategory(event = null){
    this.setState({ isLoading: true });
    if(event.target.value === 'all'){
      fetch(apiUrl + 'all')
        .then(response => response.json())
        .then(data => this.setState({ spots: data, isLoading: false }));
      return;
    }
    fetch(apiUrl + 'category/' + event.target.value)
      .then(response => response.json())
      .then(data => this.setState({ spots: data, isLoading: false }));
  }

  render() {
    const {
      spots,
      isLoading,
      showIntroductionModal,
      showAddFormModal,
      showViewSpotModal,
      selectedSpot
    } = this.state;

    const userLocation = (this.props.isGeolocationAvailable && this.props.isGeolocationEnabled && this.props.coords) ?
      {lat: this.props.coords.latitude, lng: this.props.coords.longitude} : null;

    return(
      <div className="App">
        <IntroductionModal show={showIntroductionModal} toggleIntroductionModal={this.toggleIntroductionModal} />
        <AddFormModal show={showAddFormModal} toggleAddFormModal={this.toggleAddFormModal} />
        <ViewSpotModal show={showViewSpotModal} toggleViewSpotModal={this.toggleViewSpotModal} selectedSpot={selectedSpot}/>
        <HeaderBar
          toggleIntroductionModal={this.toggleIntroductionModal}
          toggleAddFormModal={this.toggleAddFormModal}
          filterCategory={this.filterCategory}
          toggleViewSpotModal={this.toggleViewSpotModal}
        />
        {isLoading && <p>Loading ...</p>}
        {!isLoading && (
          <LeafletMap
            spots={spots}
            toggleIntroductionModal={this.toggleIntroductionModal}
            toggleViewSpotModal={this.toggleViewSpotModal}
            userLocation={userLocation}
          />
        )}
      </div>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(App);

import React from 'react';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {divIcon} from "leaflet";
import {markerMapper} from "../../functions/markerMapper";
import {renderToStaticMarkup} from "react-dom/server";
import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';
import ModalCardBanner from "../ModalCardBanner";
import {CategoryIcon} from "../CategoryIcon";

const defaultLocation = [52.160594,4.489220];

const styles = {
  loader: {
    height: '100%'
  },
  container: {
    zIndex: 0,
    position: 'absolute',
    top: '0px',
    height: `100%`,
    width: `100%`
  },
  map: {
    width: '100%',
    height: '100%',
  },
  show: {
    display: 'block',
  },
  title: {
    color: 'white',
    height: '176px'
  },
  subtitle: {
    color: 'white',
  },
  maps: {
    chip: {
      background: 'linear-gradient(30deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%)',
      width: '100%'
    },
    button: {
      border: '0',
      background: 'inherit',
      height: 'inherit',
      borderRadius: '50px',
      color: 'white',
      width: '100%'
    }
  },
  reviews: {
    width: '100%',
  }
};

const Icon = (iconName) => {
  const iconMarkup = renderToStaticMarkup(
    <img
      src={`${markerMapper(iconName)}`}
      alt={''}
    />,
  );

  return divIcon({
    html: iconMarkup,
    iconSize: [60, 72],
    iconAnchor: [30, 36],
    popupAnchor: [0, -35],
  });
};

const Markers = ({spots, toggleViewSpotModal}) => (
  spots.map((spot, key) => {
    return (
      <Marker
        key={key}
        icon={Icon(spot.category)}
        position={[spot.lat, spot.lng]}
      >
        <Popup>
            <div className="mdl-card__title" style={{
              ...styles.title,
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/backgrounds/${spot.category}.png)`
            }}>
              <h5>
                <div className="mdl-card__title-text">{spot.name}</div>
                <div className="mdl-card__subtitle-text" style={styles.subtitle}>{spot.address}</div>
                <div className="mdl-card__subtitle-text" style={styles.subtitle}>{spot.phone}</div>
                <CategoryIcon category={spot.category}/>
              </h5>
            </div>
          <button
            style={{width:'100%'}}
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            onClick={() => toggleViewSpotModal(spot)}
          >
            Toon meer informatie
          </button>
        </Popup>
      </Marker>
    )
  })
);

const LeafletMap = ({spots, toggleIntroductionModal, toggleViewSpotModal, userLocation}) => {
  // const outerBounds = spots && spots.map((spot, key) => [spot.lat, spot.lng]);

  return (
    <MapContainer
      style={styles.container}
      // bounds={outerBounds}
      center={userLocation ? [userLocation.lat, userLocation.lng] : defaultLocation}
      tap={false}
      zoom={17}
    >
      <TileLayer
        attribution={`&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Markers spots={spots} toggleViewSpotModal={toggleViewSpotModal}/>
      <div onClick={() => console.log('onclick')}>
        <Marker
          icon={Icon('you')}
          position={userLocation ? [userLocation.lat, userLocation.lng] : defaultLocation}
        >
          <Popup>
            <div className="demo-card-wide mdl-card mdl-shadow--2dp">
              <ModalCardBanner title="Hallo Stapper"
                               imagePath={`${process.env.PUBLIC_URL}/images/introduction_banner.jpg`}/>
              <div className="mdl-card__supporting-text">
                Natuurlijk willen wij ervoor zorgen dat je vanavond een hele fijne avond beleefd. Daarom is het
                belangrijk
                hieronder even een kijkje te nemen in de legenda zodat je straks niet op de verkeerde plek belandt.
              </div>
              <button
                style={{width:'100%'}}
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                onClick={() => toggleIntroductionModal()}
              >
                Toon meer informatie
              </button>
            </div>
          </Popup>
        </Marker>
      </div>
    </MapContainer>
  )
};

export default LeafletMap;

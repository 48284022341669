import React, { Component } from 'react';
import {Logo} from "../Logo";
import {Filter} from "../Filter";
import {SearchBar} from "../SearchBar";
import SearchResults from "../SearchResults";
import MobileSearchBar from "../MobileSearchBar";
import {apiUrl} from "../../Constants";

const styles = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  drawer: {
    icon: {
      marginRight:'15px',
    },
    link: {
      fontWeight: 'bold',
      textAlign: 'left',
      border:0,
    }
  },
  loading:{
    textAlign:'center',
  }
};

const MobileHeaderBar = ({
  toggleIntroductionModal,
  filterCategory,
  filterSearchResults,
  isLoading,
  searchResults,
  toggleViewSpotModal,
  closeSearchResults
}) => (
  <>
    <header className="mdl-layout__header mdl-layout--small-screen-only" style={styles}>
        <MobileSearchBar filterSearchResults={filterSearchResults} />
        <Filter filterCategory={filterCategory} />
      <SearchResults isLoading={isLoading} searchResults={searchResults} toggleViewSpotModal={toggleViewSpotModal} closeSearchResults={closeSearchResults}/>
    </header>
    <div className="mdl-layout__drawer mdl-layout--small-screen-only">
      <span className="mdl-layout-title"><Logo/>Waargaikuit</span>
      <nav className="mdl-navigation">
        <button className="mdl-navigation__link" style={styles.drawer.link} onClick={toggleIntroductionModal}>
          <i className="material-icons" style={styles.drawer.icon}>help</i> Getting started
        </button>
      </nav>
    </div>
  </>
);

class HeaderBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      searchResults: [],
    };

    this.filterSearchResults = this.filterSearchResults.bind(this);
    this.closeSearchResults = this.closeSearchResults.bind(this);
  }

  filterSearchResults(searchTerm = null){
    console.log(searchTerm);
    this.setState({ isLoading: true });
    if(searchTerm === 'all'){
      fetch(apiUrl + 'all')
        .then(response => response.json())
        .then(data => this.setState({ searchResults: data, isLoading: false }));
      return;
    }
    fetch(apiUrl + 'search/' + searchTerm)
      .then(response => response.json())
      .then(data => this.setState({ searchResults: data, isLoading: false }));
  }

  closeSearchResults(){
    this.setState({ isLoading: false, searchResults: [] });
  }

  render() {
    const {
      searchResults,
      isLoading
    } = this.state;

    const {
      toggleIntroductionModal, toggleAddFormModal, filterCategory, toggleViewSpotModal
    } = this.props;

    return(
      <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header">
        <header className="mdl-layout__header  mdl-layout--large-screen-only" style={styles}>
          <div className="mdl-layout__header-row">
            <Logo/>
            <span className="mdl-layout-title">Waar Ga Ik Uit</span>
            <div className="mdl-layout-spacer"/>
            <Filter filterCategory={filterCategory} />
            <SearchBar filterSearchResults={this.filterSearchResults} />
            {/*<button*/}
            {/*  onClick={toggleAddFormModal}*/}
            {/*  className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored"*/}
            {/*>*/}
            {/*  <i className="material-icons">add</i>*/}
            {/*</button>*/}
          </div>
          <SearchResults isLoading={isLoading} searchResults={searchResults} toggleViewSpotModal={toggleViewSpotModal} closeSearchResults={this.closeSearchResults}/>
        </header>
        <MobileHeaderBar
          toggleIntroductionModal={toggleIntroductionModal}
          filterCategory={filterCategory}
          filterSearchResults={this.filterSearchResults}
          toggleAddFormModal={toggleAddFormModal}
          isLoading={isLoading}
          searchResults={searchResults}
          toggleViewSpotModal={toggleViewSpotModal}
          closeSearchResults={this.closeSearchResults}
        />
      </div>
    )
  }
}

export default HeaderBar;

import barIcon from "../images/categories/icons/bar.svg";
import boardGameBarIcon from "../images/categories/icons/boardgame_bar.svg";
import casinoIcon from "../images/categories/icons/casino.svg";
import cinemaIcon from "../images/categories/icons/cinema.svg";
import cocktailBarIcon from "../images/categories/icons/cocktail_bar.svg";
import concertHallIcon from "../images/categories/icons/concert_hall.svg";
import discoIcon from "../images/categories/icons/disco.svg";
import gayBarIcon from "../images/categories/icons/gay_bar.svg";
import karaokeBarIcon from "../images/categories/icons/karaoke_bar.svg";
import poolHallIcon from "../images/categories/icons/pool_hall.svg";
import shishaBarIcon from "../images/categories/icons/shisha_bar.svg";
import stripClubIcon from "../images/categories/icons/strip_club.svg";
import youIcon from "../images/categories/icons/you.svg";

export const iconMapper = (category) => {
  if (category === 'bar') {
    return barIcon;
  }
  if (category === 'boardgame_bar') {
    return boardGameBarIcon;
  }
  if (category === 'casino') {
    return casinoIcon;
  }
  if (category === 'cinema') {
    return cinemaIcon;
  }
  if (category === 'cocktail_bar') {
    return cocktailBarIcon;
  }
  if (category === 'concert_hall') {
    return concertHallIcon;
  }
  if (category === 'disco') {
    return discoIcon;
  }
  if (category === 'gay_bar') {
    return gayBarIcon;
  }
  if (category === 'karaoke_bar') {
    return karaokeBarIcon;
  }
  if (category === 'pool_hall') {
    return poolHallIcon;
  }
  if (category === 'shisha_bar') {
    return shishaBarIcon;
  }
  if (category === 'strip_club') {
    return stripClubIcon;
  }
  if (category === 'you') {
    return youIcon;
  }
};

import React from 'react';

const styles = {
  title: {
    color:'white',
  },
  description: {
    height:'100px',
  },
  button: {
    background: 'blue',
    color: 'white',
  }
};

export const SearchResultCard = ({result, toggleViewSpotModal}) => (
  <div className="demo-card-square mdl-card mdl-shadow--2dp">
    <div className="mdl-card__title" style={{...styles.title, backgroundImage: `url(${process.env.PUBLIC_URL}/images/backgrounds/${result.category}.png)`}}>
      <h5>
        <div className="mdl-card__title-text">{result.name}</div>
      </h5>
    </div>
    <div className="mdl-card__supporting-text" style={styles.description}>
      {result.description}
    </div>
    <div className="mdl-card__actions mdl-card--border">
      <button className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" onClick={()=>toggleViewSpotModal(result)}>
        View details
      </button>
    </div>
  </div>
);

import React from 'react';

const styles = {
  modalBackground: {
    backgroundColor:'black',
    position: 'fixed',
    height: '100%',
    width: '100%',
    opacity: '0.5'
  }
};

const ModalBackground = ({closeFunction}) => (
    <div style={styles.modalBackground} onClick={closeFunction}/>
);

export default ModalBackground;

import React from 'react';
import {iconMapper} from "../../functions/iconMapper";

const getLabelfromCategory = (category) => {
  if (category === 'bar') {
    return "Bar";
  }
  if (category === 'boardgame_bar') {
    return "Boardgame bar";
  }
  if (category === 'casino') {
    return "Casino";
  }
  if (category === 'cinema') {
    return "Cinema";
  }
  if (category === 'cocktail_bar') {
    return "Cocktail bar";
  }
  if (category === 'concert_hall') {
    return "Concert hall";
  }
  if (category === 'disco') {
    return "Disco";
  }
  if (category === 'gay_bar') {
    return "Gay bar";
  }
  if (category === 'karaoke_bar') {
    return "Karaoke bar";
  }
  if (category === 'pool_hall') {
    return "Pool hall";
  }
  if (category === 'shisha_bar') {
    return "Shisha bar";
  }
  if (category === 'strip_club') {
    return "Strip club";
  }
  if (category === 'you') {
    return "Dit ben jij";
  }
};

const styles = {
  marginTop: '10px',
};

export const CategoryIcon = ({category}) => (
  <div style={styles}>
    <img src={iconMapper(category)} className="mdl-chip__contact" alt="category" />
    <span className="mdl-chip__text">{getLabelfromCategory(category)}</span>
  </div>
);

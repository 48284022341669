import React from 'react';
import {SearchResultCard} from "../SearchResultCard";

const styles = {
  show: {
    display: 'block'
  }
};

function getResultsInChunks(searchResults) {
  const searchResultsInChunks = [];
  var i,j,temparray,chunk = 3;
  for (i=0,j=searchResults.length; i<j; i+=chunk) {
    temparray = searchResults.slice(i,i+chunk);
    searchResultsInChunks.push(temparray);
  }
  return searchResultsInChunks;
}

const SearchResults = ({isLoading, searchResults, toggleViewSpotModal, closeSearchResults}) => (
  <>
  {isLoading && <span style={styles.loading}>loading...</span>}
  {!isLoading && getResultsInChunks(searchResults).map(chunk => {
    return(
      <div  className="mdl-grid">
        {chunk.map(result => {
          return(
            <div className="mdl-cell mdl-cell--4-col">
              <SearchResultCard result={result} toggleViewSpotModal={toggleViewSpotModal} />
            </div>
          )})}
      </div>
    )})
  }
  {searchResults.length !== 0 && (
    <button className="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect" onClick={closeSearchResults}>
      <i className="material-icons">close</i>
    </button>
  )}
</>
);

export default SearchResults;

import React from 'react';

export const Filter = ({filterCategory}) => (
  <nav className="mdl-navigation">
    <select className="form-control" onChange={filterCategory}
    >
      <option value="all">All</option>
      <option value="pool_hall">Pool Centrum</option>
      <option value="bar">Bar</option>
      <option value="boardgame_bar">Boardgame Bar</option>
      <option value="cinema">Cinema</option>
      <option value="disco">Discotheek</option>
      <option value="casino">Casino</option>
      <option value="cocktail_bar">Cocktail Bar</option>
      <option value="concert_hall">Concert Zaal</option>
      <option value="gay_bar">Gay Bar</option>
      <option value="shisha_bar">Shisha Bar</option>
      <option value="strip_club">Strip Club</option>
      <option value="karaoke_bar">Karaoke Bar</option>
    </select>
  </nav>
);

import React from 'react';
import ModalBackground from "../ModalBackground";
import mapsIcon from "../../images/icons/google_maps.svg"
import browserIcon from "../../images/icons/browser.svg"
import {CategoryIcon} from "../CategoryIcon";

const styles = {
  show: {
    display: 'block',
  },
  title:{
    color: 'white',
    height: '176px'
  },
  subtitle: {
    color:'white',
  },
  maps: {
    chip: {
      background: 'linear-gradient(30deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%)',
    },
    button: {
      border:'0',
      background:'inherit',
      height:'inherit',
      borderRadius:'50px',
      color:'white'
    }
  },
  reviews:{
    width: '100%',
  }
};

const ViewSpotModal = ({show, toggleViewSpotModal, selectedSpot}) => {
  const mockedSpot = {
    address: "Street name 111, 2312 AB City",
    category: "bar",
    description: "Description is shown here",
    drinks: [],
    googlePlaceId: "",
    image: "",
    lat: "",
    lng: "",
    name: "The name",
    phone: "+31 12 345 6789",
  };

  const {
    address,
    category,
    description,
    name,
    phone,
    lat,
    lng
  } = selectedSpot || mockedSpot;

  return(
    <div
      className={`modal fade ${show && 'in'}`}
      style={show ? styles.show : {}}
    >
      <ModalBackground closeFunction={toggleViewSpotModal} />
      <div className="modal-dialog">
        <div className="demo-card-wide mdl-card mdl-shadow--2dp">
          <div className="mdl-card__title" style={{...styles.title, backgroundImage: `url(${process.env.PUBLIC_URL}/images/backgrounds/${category}.png)`}}>
            <h5>
              <div className="mdl-card__title-text">{name}</div>
              <div className="mdl-card__subtitle-text" style={styles.subtitle}>{address}</div>
              <div className="mdl-card__subtitle-text" style={styles.subtitle}>{phone}</div>
              <CategoryIcon category={category} />
            </h5>
          </div>
          <div className="mdl-grid">
            <span style={styles.maps.chip} className="mdl-chip mdl-chip--contact">
              <a style={styles.maps.button} href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
                <img src={mapsIcon} className="mdl-chip__contact" alt="Link to google maps location" />
                <span className="mdl-chip__text">Open in maps</span>
              </a>
            </span>
            <span style={styles.maps.chip} className="mdl-chip mdl-chip--contact">
              <button style={styles.maps.button}>
                <img src={browserIcon} className="mdl-chip__contact" alt="Link to website" />
                <span className="mdl-chip__text">Go to Website</span>
              </button>
            </span>
          </div>
          <div className="mdl-card__supporting-text">
            {description}
          </div>
          <div className="mdl-card__menu">
            <button className="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect" onClick={toggleViewSpotModal}>
              <i className="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </div>
)};

export default ViewSpotModal;

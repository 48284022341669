import React from 'react';
import ModalBackground from "../ModalBackground";
import ModalCardBanner from "../ModalCardBanner";

const styles = {
  show: {
    display: 'block'
  }
};

const AddFormModal = ({show, toggleAddFormModal}) => (
  <div
    className={`modal fade ${show && 'in'}`}
    style={show ? styles.show : {}}
  >
    <ModalBackground closeFunction={toggleAddFormModal} />
    <div className="modal-dialog">
      <div className="demo-card-wide mdl-card mdl-shadow--2dp">
        <ModalCardBanner title="Nieuwe spot toevoegen" imagePath="/images/new_spot.jpg" />
        <div className="mdl-card_form">
          <p>Klik de locatie aan op de kaart</p>
          <div id="map-canvas2"/>
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <input className="mdl-textfield__input" type="text" />
                <label className="mdl-textfield__label" htmlFor="spotName">Naam</label>
            </div>
            <div className="mdl-textfield mdl-js-textfield">
              <textarea className="mdl-textfield__input" type="text" rows= "5" />
              <label className="mdl-textfield__label" htmlFor="spotDescription">Omschrijving</label>
            </div>
            <select className="form-control" >
              <option value="">Selecteer een categorie</option>
              <option value="pool_hall">Pool Centrum</option>
              <option value="bar">Bar</option>
              <option value="boardgame_bar">Boardgame Bar</option>
              <option value="cinema">Cinema</option>
              <option value="disco">Discotheek</option>
              <option value="casino">Casino</option>
              <option value="cocktail_bar">Cocktail Bar</option>
              <option value="concert_hall">Concert Zaal</option>
              <option value="gay_bar">Gay Bar</option>
              <option value="shisha_bar">Shisha Bar</option>
              <option value="strip_club">Strip Club</option>
              <option value="karaoke_bar">Karaoke Bar</option>
            </select>

            <div className="mdl-textfield mdl-js-textfield">
              <p htmlFor="spotStreet">Straat</p>
              <input className="mdl-textfield__input" type="text"/>
            </div>
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <p htmlFor="spotStreetNumber">Huisnummer</p>
              <input className="mdl-textfield__input" type="text"/>
            </div>
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <p htmlFor="spotPostalCode">Postcode</p>
              <input className="mdl-textfield__input" type="text"/>
            </div>
        </div>
        <div className="modal-footer">
          <button onClick={toggleAddFormModal}
                  className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
          >
            Annuleren
          </button>
          <button //onClick="sendForm();"
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
          >
            Opslaan
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default AddFormModal;

import React, { Component } from 'react';

export class MobileSearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
    this.handleSearchTermChange= this.handleSearchTermChange.bind(this);
    this.submit= this.submit.bind(this);
  }

  handleSearchTermChange(event){
    this.setState({searchTerm: event.target.value});
  }

  submit(event){
    event.preventDefault();
    this.props.filterSearchResults(this.state.searchTerm);
  }

  render() {
    return(
      <form id="mobilemapsearch" onSubmit={this.submit}>
        <div className="mdl-textfield mobile-search-field">
          <label className="mdl-button mdl-js-button mdl-button--icon" htmlFor="mobilesearchquery">
            <i className="material-icons">search</i>
          </label>
          <input className="mdl-textfield__input" type="text" id="mobilesearchquery" placeholder={"Uitgaansgelegenheid"} autoComplete="off" onChange={this.handleSearchTermChange}/>
        </div>
      </form>
    )
  }
}

export default MobileSearchBar;

import React, { Component } from 'react';

const styles = {
  submitButton: {
    marginLeft: "15px",
  }
};

export class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
    this.handleSearchTermChange= this.handleSearchTermChange.bind(this);
    this.submit= this.submit.bind(this);
  }

  handleSearchTermChange(event){
    this.setState({searchTerm: event.target.value});
  }

  submit(event){
    event.preventDefault();
    this.props.filterSearchResults(this.state.searchTerm);
  }

  render() {
    return(
      <form className="navbar-form navbar-left" id="mapsearch" role="search" onSubmit={this.submit}>
        <div className="form-group" >
          <input type="text" className="form-control" id="searchquery" name="searchquery" placeholder="Uitgaansgelegenheid" onChange={this.handleSearchTermChange}/>
        </div>
        <button type="submit" style={styles.submitButton} className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored">
          <i className="material-icons">search</i>
        </button>
      </form>
    )
  }
}

export default SearchBar;

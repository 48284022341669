import barIcon from "../images/categories/markers/bar.svg";
import boardGameBarIcon from "../images/categories/markers/boardgame_bar.svg";
import casinoIcon from "../images/categories/markers/casino.svg";
import cinemaIcon from "../images/categories/markers/cinema.svg";
import cocktailBarIcon from "../images/categories/markers/cocktail_bar.svg";
import concertHallIcon from "../images/categories/markers/concert_hall.svg";
import discoIcon from "../images/categories/markers/disco.svg";
import gayBarIcon from "../images/categories/markers/gay_bar.svg";
import karaokeBarIcon from "../images/categories/markers/karaoke_bar.svg";
import poolHallIcon from "../images/categories/markers/pool_hall.svg";
import shishaBarIcon from "../images/categories/markers/shisha_bar.svg";
import stripClubIcon from "../images/categories/markers/strip_club.svg";
import youIcon from "../images/categories/markers/you.svg";

export const markerMapper = (category) => {
  if (category === 'bar') {
    return barIcon;
  }
  if (category === 'boardgame_bar') {
    return boardGameBarIcon;
  }
  if (category === 'casino') {
    return casinoIcon;
  }
  if (category === 'cinema') {
    return cinemaIcon;
  }
  if (category === 'cocktail_bar') {
    return cocktailBarIcon;
  }
  if (category === 'concert_hall') {
    return concertHallIcon;
  }
  if (category === 'disco') {
    return discoIcon;
  }
  if (category === 'gay_bar') {
    return gayBarIcon;
  }
  if (category === 'karaoke_bar') {
    return karaokeBarIcon;
  }
  if (category === 'pool_hall') {
    return poolHallIcon;
  }
  if (category === 'shisha_bar') {
    return shishaBarIcon;
  }
  if (category === 'strip_club') {
    return stripClubIcon;
  }
  if (category === 'you') {
    return youIcon;
  }
};

import React from 'react';
import ModalBackground from "../ModalBackground";
import ModalCardBanner from "../ModalCardBanner";
import {CategoryIcon} from "../CategoryIcon";
import {iconMapper} from "../../functions/iconMapper";

const styles = {
  show: {
    display: 'block',
  },
  iconWrapper: {
    backgroundColor:'#f49919',
  },
  icon: {
    marginTop:'10px',
  },
  modalBackground: {
    backgroundColor:'black',
    position: 'fixed',
    height: '100%',
    width: '100%',
    opacity: '0.5'
  }
};

const IntroductionModal = ({show, toggleIntroductionModal}) => (
  <div
    className={`modal fade ${show && 'in'}`}
    style={show ? styles.show : {}}
  >
    <ModalBackground closeFunction={toggleIntroductionModal} />
    <div className="modal-dialog">
      <div className="demo-card-wide mdl-card mdl-shadow--2dp">
        <ModalCardBanner title="Hallo Stapper" imagePath={`${process.env.PUBLIC_URL}/images/introduction_banner.jpg`} />
        <div className="mdl-card__supporting-text">
          Natuurlijk willen wij ervoor zorgen dat je vanavond een hele fijne avond beleefd. Daarom is het belangrijk
          hieronder even een kijkje te nemen in de legenda zodat je straks niet op de verkeerde plek belandt.
        </div>
        <div className="mdl-card__actions mdl-card--border">
          <div className="demo-list-action mdl-list">
            <CategoryIcon category={"pool_hall"}/>
            <CategoryIcon category={"bar"}/>
            <CategoryIcon category={"boardgame_bar"}/>
            <CategoryIcon category={"cinema"}/>
            <CategoryIcon category={"disco"}/>
            <CategoryIcon category={"cocktail_bar"}/>
            <CategoryIcon category={"casino"}/>
            <CategoryIcon category={"gay_bar"}/>
            <CategoryIcon category={"concert_hall"}/>
            <CategoryIcon category={"shisha_bar"}/>
            <CategoryIcon category={"strip_club"}/>
            <CategoryIcon category={"karaoke_bar"}/>
            <div style={styles.icon}>
              <img src={iconMapper("you")} className="mdl-chip__contact" alt="category" />
              <span className="mdl-chip__text"><b>En dit</b>, ben jij</span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            onClick={toggleIntroductionModal}
          >
            Begin
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default IntroductionModal;
